import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, List, Avatar, Typography, Tooltip, Upload, message, Progress, Dropdown, Drawer, Modal, Select } from 'antd';
import { 
  // PiPhoneOutgoingLight, 
  PiPaperclipLight, PiSmileyLight } from "react-icons/pi";
// import { IoInformationCircleOutline } from "react-icons/io5";
// import { MdVideoCameraFront } from 'react-icons/md';
import { BiDotsVerticalRounded, BiSend } from 'react-icons/bi';
import { BsPersonCircle, BsX } from 'react-icons/bs';
import { AiOutlineUserDelete, AiOutlineUserAdd } from 'react-icons/ai';
import { formatDistanceToNow } from 'date-fns';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';

const { Text } = Typography;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ChatWindow = ({ selectedChat, onChatUpdate }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); 
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);  
  const [users, setUsers] = useState([]);
  const userToken = useSelector(state => state.auth.userToken);
  const user = useSelector(state => state.auth.userInfo);
  const messageEndRef = useRef(null);
  const messagePollingInterval = useRef(null);
  const fileInputRef = useRef(null);
  const [prevMessages, setPrevMessages] = useState([]);

  useEffect(() => {
    if (selectedChat && userToken) {
      fetchMessages();
      messagePollingInterval.current = setInterval(fetchMessages, 3000);
    }

    return () => {
      if (messagePollingInterval.current) {
        clearInterval(messagePollingInterval.current);
      }
    };
  }, [selectedChat, userToken]);

  useEffect(() => {
    if (messages.length > 0 && prevMessages[prevMessages.length-1]?._id !== messages[messages.length-1]?._id) {
      setPrevMessages(messages);
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/users?fields=_id lastName firstName employeeId -attendance -performanceReviews&${user.role === 'admin' ? '' : `department=${user?.department}`}`, {
          headers: { Authorization: `Bearer ${userToken}` }
        });
        if (response.data?.status === 'success') {
          const usersList = response.data.data?.users || [];
          // Filter out users who are already in the group
          const availableUsers = usersList.filter(u => 
            !selectedChat?.users?.some(member => member._id === u._id)
          );
          setUsers(availableUsers);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        message.error('Failed to fetch users');
      }
    };

    if (showAddMembers && selectedChat?.isGroupChat) {
      fetchUsers();
    }
  }, [showAddMembers, selectedChat, userToken, user]);
 
    
  const fetchMessages = async () => {
    if (!selectedChat?._id || !userToken) {
      console.error('No chat selected or missing token');
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/message/${selectedChat._id}`, {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const data = await response.json();
      if (data?.status === 'success' && Array.isArray(data?.data)) {
        setMessages(data.data);
      } else {
        console.error('Invalid message data structure:', data);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
    }
  };

  const handleSelect = (e) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const file = e.target.files[0];
  
    if (file) {
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        message.error('Only .jpg, .jpeg, .png, .pdf, .doc, .docx files are allowed.');
        return;
      }
  
      // Validate file size (e.g., max 5MB)
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        message.error('File size should be less than 5MB.');
        return;
      }
  
      // Set the file and preview
      setSelectedImage(file);
      if (file.type.startsWith('image/')) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        // For documents, set a generic preview (e.g., placeholder text or icon)
        setPreviewUrl('document');
      }
  
      setUploadProgress(0);
    }
  };  

  const removeSelectedImage = () => {
    setSelectedImage(null);
    setPreviewUrl('');
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const sendMessage = async () => {
    if ((!newMessage.trim() && !selectedImage) || !selectedChat || !userToken || isSending) return;

    try {
      setIsSending(true);
      if (selectedImage) {
        setIsImageLoading(true);
      }
      const formData = new FormData();

      if (newMessage.trim()) {
        formData.append('content', newMessage.trim());
      }
      if (selectedImage) {
        formData.append('file', selectedImage);
      }
      formData.append('chatId', selectedChat._id);

      const response = await axios.post(`${BASE_URL}/api/message`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${userToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      if (response.data) {
        // Add the uploaded message to the chat messages
        setMessages((prevMessages) => [...prevMessages, response.data]);

        // Reset input states
        setNewMessage('');
        setSelectedImage(null);
        setPreviewUrl('');

        // If it's an image message, wait for the image to load before hiding progress
        if (response.data.messageType === 'image') {
          const img = new Image();
          img.src = response.data.content;
          img.onload = () => {
            setUploadProgress(0);
            setIsImageLoading(false);
          };
          img.onerror = () => {
            setUploadProgress(0);
            setIsImageLoading(false);
            message.error('Error loading image');
          };
        } else {
          setUploadProgress(0);
          setIsImageLoading(false);
        }

        scrollToBottom();
      }
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('Failed to send message');
      setUploadProgress(0);
      setIsImageLoading(false);
    } finally {
      setIsSending(false);
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/message/${messageId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });

      if (response.ok) {
        // Immediately update UI by filtering out the deleted message
        setMessages(prevMessages => prevMessages.filter(msg => msg._id !== messageId));
      } else {
        message.error('Failed to delete message');
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      message.error('Failed to delete message');
    }
  };

  const handleReaction = async (messageId, emoji) => {
    try {
      const response = await fetch(`${BASE_URL}/api/message/react`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify({ messageId, emoji })
      });

      if (!response.ok) {
        throw new Error('Failed to add reaction');
      }

      const data = await response.json();
      if (data?.status === 'success') {
        setMessages(prevMessages =>
          prevMessages.map(msg =>
            msg._id === messageId ? data.data : msg
          )
        );
      }
    } catch (error) {
      console.error('Error adding reaction:', error);
      message.error('Failed to add reaction');
    }
  };

  const handleEmojiClick = (emoji) => {
    setNewMessage(prev => prev + emoji);
    setShowEmojiPicker(false);
  };

  const getMessageActions = (msg) => {
    const isOwnMessage = msg?.sender?._id === user?.id;
    return [
      ...(msg?.messageType === 'document' || msg?.messageType === 'image' ? [{
        key: 'view',
        label: <a
          href={msg?.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>,
      }] : []),
      {
        key: 'react',
        label: 'React',
        children: [
          {
            key: 'like',
            label: (
              <div className="reaction-menu-item">
                <span>👍</span>
                <span>Like</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '👍')
          },
          {
            key: 'love',
            label: (
              <div className="reaction-menu-item">
                <span>❤️</span>
                <span>Love</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '❤️')
          },
          {
            key: 'laugh',
            label: (
              <div className="reaction-menu-item">
                <span>😄</span>
                <span>Haha</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '😄')
          },
          {
            key: 'wow',
            label: (
              <div className="reaction-menu-item">
                <span>😮</span>
                <span>Wow</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '😮')
          },
          {
            key: 'sad',
            label: (
              <div className="reaction-menu-item">
                <span>😢</span>
                <span>Sad</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '😢')
          },
          {
            key: 'angry',
            label: (
              <div className="reaction-menu-item">
                <span>😠</span>
                <span>Angry</span>
              </div>
            ),
            onClick: () => handleReaction(msg._id, '😠')
          }
        ]
      },
      ...(isOwnMessage ? [{
        key: 'delete',
        label: 'Delete',
        onClick: () => deleteMessage(msg._id)
      }] : [])
    ];
  };

  const handleRemoveMember = async (memberId) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/chat/groupremove`,
        {
          chatId: selectedChat._id,
          userId: memberId
        },
        {
          headers: { Authorization: `Bearer ${userToken}` }
        }
      );

      if (response.data?.status === 'success') {
        message.success('Member removed successfully');
        // Update the chat in parent component
        onChatUpdate(response.data.data);
      } else {
        throw new Error(response.data?.message || 'Failed to remove member');
      }
    } catch (error) {
      console.error('Error removing member:', error);
      message.error(error.message || 'Failed to remove member');
    }
  };

  const handleAddMember = async (userId) => {
    if (!userId) {
      message.warning('Please select a user to add');
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/chat/addToGroup`,
        {
          chatId: selectedChat._id,
          users: [userId] // Send as array since backend expects an array
        },
        {
          headers: { Authorization: `Bearer ${userToken}` }
        }
      );

      if (response.data?.status === 'success') {
        message.success('Member added successfully');
        onChatUpdate(response.data.data);
        setShowAddMembers(false);
      }
    } catch (error) {
      console.error('Error adding member:', error);
      message.error(error.response?.data?.message || 'Failed to add member');
    }
  };

  

  const renderAddMembersDrawer = () => {
    return (
      <Drawer 
        title="Add Members"
        placement="right"
        onClose={() => {
          setShowAddMembers(false);
        }}
        open={showAddMembers}
        width={300}
      >
        <div className="add-members-container">
          <Select
            style={{ width: '100%' }}
            placeholder="Select user to add"
            onChange={(value) => handleAddMember(value)}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={users.map(user => ({
              value: user._id,
              label: `${user.firstName} ${user.lastName}`
            }))}
          />
        </div>
      </Drawer>
    );
  };

  const renderMembersList = () => {
    if (!selectedChat?.isGroupChat) return null;

    const isGroupAdmin = selectedChat.groupAdmins?.some(adminId => adminId?._id === user?.id) || user?.role === 'admin';

    return (
      <Drawer
        style={{backgroundColor: '#f5f5f5'}}
        title={
          <div className="members-drawer-header d-flex justify-content-between">
            
            <div className="members-count">
              {selectedChat?.users?.length} members
            </div>
            <div className="header-content"> 
              {isGroupAdmin && (
                <Button 
                  // type="primary"
                  icon={<AiOutlineUserAdd />}
                  onClick={() => {
                    setShowMembers(false);
                    setShowAddMembers(true);
                  }}
                >  
                </Button>
              )}
            </div>
          </div>
        }
        placement="right"
        onClose={() => setShowMembers(false)}
        open={showMembers}
        width={300}
      >
        <div className="members-list">
          {selectedChat?.users?.map((member) => (
            
            <div key={member._id} className="member-item">
              <div className="member-info">
                <Avatar
                  size={40}
                  icon={<BsPersonCircle size={24} />}
                  src={member.profilePicture}
                /> 
                <div className="member-details">
                  <div className="member-name">
                    {member.firstName} {member.lastName}
                    {member._id === user?.id && <span className="current-user-tag"> (You)</span>}
                  </div>
                  <div className="member-role">
                  {selectedChat.groupAdmins?.map(id => id._id.toString()).includes(member?._id?.toString()) ? 'Admin' : 'Member'}
                 
                  
                  </div>
                </div>
              </div> 
              {isGroupAdmin && member._id !== user?.id && (
                <Button
                  type="text"
                  icon={<AiOutlineUserDelete style={{ color: '#ff4d4f' }} />}
                  className="member-actions"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: 'Remove Member',
                      content: `Are you sure you want to remove ${member.firstName} ${member.lastName} from the group?`,
                      okText: 'Remove',  
                      cancelText: 'Cancel',
                      onOk: () => handleRemoveMember(member._id)
                    });
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </Drawer>
    );
  };

  return (
    <div className="chat-window-container">
      {selectedChat ? (
        <>
          <div className="chat-header">
            <div className="chat-info">
              <Avatar
                size={40}
                icon={<BsPersonCircle size={24} />}
                src={selectedChat.isGroupChat ? selectedChat.groupAvatar : selectedChat.users?.find(u => u?._id !== user?.id)?.profilePicture}
              />
              <div className="chat-details">
                <h3>{selectedChat.isGroupChat ? selectedChat.chatName : `${selectedChat.users?.find(u => u?._id !== user?.id)?.firstName} ${selectedChat.users?.find(u => u?._id !== user?.id)?.lastName}`}</h3>
                {selectedChat.isGroupChat && (
                  <Button 
                    type="link" 
                    onClick={() => setShowMembers(true)}
                    className="view-members-btn"
                  >
                    View {selectedChat?.users?.length} members
                  </Button>
                )}
              </div>
            </div>
            {/* <div className="chat-actions">
              <Tooltip title="Voice Call">
                <Button type="text" icon={<PiPhoneOutgoingLight size={20} />} />
              </Tooltip>
              <Tooltip title="Video Call">
                <Button type="text" icon={<MdVideoCameraFront size={20} />} />
              </Tooltip>
              <Tooltip title="Chat Info">
                <Button type="text" icon={<IoInformationCircleOutline size={20} />} />
              </Tooltip>
            </div> */}
          </div>
          <div className="message-list">
            <List
              dataSource={messages}
              renderItem={message => {
                const isOwnMessage = message?.sender?._id === user?.id;
                const messageTime = message?.createdAt ? formatDistanceToNow(new Date(message.createdAt), { addSuffix: true }) : '';
                return (
                  <List.Item className={`message-item ${isOwnMessage ? 'own-message' : ''}`}> 
                    <div className="message-content">
                      {!isOwnMessage && <Avatar
                        className="message-avatar"
                        icon={<BsPersonCircle size={24} />}
                        src={message.sender?.image}
                        alt={message.sender?.firstName}
                      />}
                      <div className="message-bubble">
                        {!isOwnMessage && (
                          <Text className="sender-name" id="sender-name">
                            {message?.sender?.firstName} {message.sender?.lastName}
                          </Text>
                        )}
                        {message?.messageType !== 'image' && message?.messageType !== 'document' && (
                          <div className="message-text"
                          dangerouslySetInnerHTML={{ __html: message?.content }}
                          style={{ whiteSpace: "pre-wrap" }}
                          >
                          </div>
                        )}
                        {message?.messageType === 'image' && (
                          <div className="message-image">
                            <img src={message?.content} width={300} height={250} alt="Message attachment" />
                          </div>
                        )}
                        {message?.messageType === 'document' && (
                          <div className="message-document">
                            <div className="document-preview">
                              <div className="document-icon">
                                📝
                              </div>
                              <div className="document-info">
                                <span className="document-name">{message?.fileName}</span>
                                <span className="document-size">
                                  _{(message?.fileSize / (1024 * 1024)).toFixed(2)} MB
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <Text className="message-time">
                          {messageTime}
                        </Text>
                        {message?.reactions?.length > 0 && (
                          <div className="message-reactions">
                            {message?.reactions?.map((reaction, index) => { 
                              // Find the user details from the message.users array 
                              return (
                                <Tooltip
                                  key={index}
                                  title={`${reaction?.user?.firstName}`}
                                  placement="top"
                                >
                                  <span
                                    className="reaction"
                                    onClick={() => handleReaction(message._id, reaction.emoji)}
                                  >
                                    {reaction.emoji}
                                    {message.reactions.filter(r => r.emoji === reaction.emoji).length > 1 && (
                                      <span className="reaction-count">
                                        {message.reactions.filter(r => r.emoji === reaction.emoji).length}
                                      </span>
                                    )}
                                  </span>
                                </Tooltip>
                              );
                            })}
                          </div>
                        )}
                        <div className="message-actions">
                          <Dropdown
                            menu={{ items: getMessageActions(message) }}
                            trigger={['click']}
                            placement="bottomRight"
                          >
                            <Button
                              type="text"
                              icon={<BiDotsVerticalRounded />}
                              className="action-button"
                            />
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
            <div ref={messageEndRef} />
          </div>

          <div className="message-input-area">
            {(uploadProgress > 0 || isImageLoading) && (
              <div className="upload-progress" style={{ maxWidth: '40%' }}>
                <Progress
                  percent={uploadProgress}
                  size="small"
                  status={isImageLoading && uploadProgress === 100 ? "active" : "normal"}
                />
              </div>
            )}
            <div className="input-section">
              <div className="input-buttons">
                <Tooltip title="Add Emoji">
                  <Button
                    type="text"
                    icon={<PiSmileyLight />}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  />
                </Tooltip>
                <Tooltip title="Attach File">
                  <Button
                    type="text"
                    icon={<PiPaperclipLight />}
                    onClick={() => fileInputRef.current?.click()}
                  />
                </Tooltip>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleSelect}
                  style={{ display: 'none' }}
                  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                />
              </div>
              <Input.TextArea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message..."
                autoSize={{ minRows: 1, maxRows: 4 }}
                disabled={isSending}
              />
              <Button
                type="primary"
                icon={<BiSend />}
                onClick={sendMessage}
                loading={isSending}
                disabled={(!newMessage.trim() && !selectedImage) || isSending}
              />
            </div>

            {previewUrl && (
              <div className="preview-section">
                <div className="preview-container">
                  <Button
                    className="remove-preview"
                    icon={<BsX />}
                    onClick={removeSelectedImage}
                  />
                  {selectedImage?.type.startsWith('image/') ? (
                    <img src={previewUrl} alt="Preview" width={200} height={200} />
                  ) : (
                    <div className="document-preview">
                      <div className="document-icon">
                        📝
                      </div>
                      <div className="document-info">
                        <span className="document-name">{selectedImage?.name}</span>
                        <span className="document-size">
                          _{(selectedImage?.size / (1024 * 1024)).toFixed(2)} MB
                        </span>
                      </div>
                    </div>
                  )}
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="upload-progress">
                      <Progress
                        percent={uploadProgress}
                        size="small"
                        status="active"
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}


            {showEmojiPicker && (
              <div className="emoji-picker-container">
                <EmojiPicker
                  onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject.emoji)}
                  disableAutoFocus
                  native
                />
              </div>
            )}
          </div>
          {renderMembersList()}
          {renderAddMembersDrawer()}
        </>
      ) : (
        <div className="no-chat-selected">
          <Text>Select a chat to start messaging</Text>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
