// employeeSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { objectToQueryParams } from "../utils";

const apiUrl = `${process.env.REACT_APP_BASE_URL}/api`; // Adjust the base URL as needed

/* Fetch employees based on pagination */
export const fetchEmployees = createAsyncThunk(
  "employee/fetchEmployees",
  async ({ pagination = { page: 1, limit: 10 }, filter={}, sort='firstName,lastName', fields='-__v -attendance' }, thunkAPI) => {
    const params = {...pagination, ...filter, sort, fields }
    const state = thunkAPI.getState();
    const token = state.auth.userInfo.userToken;
    const response = await axios.get(
      `${apiUrl}/users?${objectToQueryParams(params)}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return {
      employees: response?.data?.data?.users,
      total: response?.data?.total, // Total number of employees from the backend
    };
  }
);

/* Fetch all employees specific fields (names, id, email) also to show users in sales*/
export const fetchAllEmployees = createAsyncThunk(
  "employee/fetchAllEmployees",
  async ({ pagination = {}, filter={}, sort='firstName,lastName', fields='_id lastName firstName employeeId -attendance -performanceReviews' }, thunkAPI) => {
    const params = {...pagination, ...filter, sort, fields }
    const state = thunkAPI.getState();
    const token = state.auth.userInfo.userToken;
    const response = await axios.get(
      `${apiUrl}/users?${objectToQueryParams(params)}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return {
      allEmployees: response?.data?.data?.users,
      total: response?.data?.total, // Total number of employees from the backend
    };
  }
);

// Update employee status
export const updateEmployeeStatus = createAsyncThunk(
  "employee/updateEmployeeStatus",
  async (employee, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.patch(
      `${apiUrl}/users/${employee._id}`,
      {
        status: employee.status,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data?.data?.user;
  }
);

// Update an existing employee
export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const { id, ...data } = employeeData;
    const response = await axios.patch(`${apiUrl}/users/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data;
  }
);

// Delete an employee
export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (employeeId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/users/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return employeeId;
  }
);

// Fetch all departments
export const fetchDepartments = createAsyncThunk(
  "employee/fetchDepartments",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${apiUrl}/departments`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.departments;
    } catch (error) {
      console.error('Error fetching departments:', error);
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch departments');
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "employee/updateDepartments",
  async ({ departmentId, data, }, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.patch(
      `${apiUrl}/departments/${departmentId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.departments;
  }
);

export const deleteDepartment = createAsyncThunk(
  "employee/deleteDepartments",
  async (departmentId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.delete(
      `${apiUrl}/departments/${departmentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.departments;
  }
);

// Fetch designations for a specific department
export const fetchDesignations = createAsyncThunk(
  "employee/fetchDesignations",
  async (departmentId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.get(
      `${apiUrl}/designations/department/${departmentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.designations;
  }
);
export const updateDesignations = createAsyncThunk(
  "employee/updateDesignations",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const { id, ...data } = employeeData;
    const response = await axios.patch(`${apiUrl}/designations/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data?.data?.user;
  }
);

// Delete an employee
export const deleteDesignations = createAsyncThunk(
  "employee/deleteDesignations",
  async (designationId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/designations/${designationId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return designationId;
  }
);

// Delete an estimate
export const deleteSale = createAsyncThunk(
  "employee/deleteEstimates",
  async (saleId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/sales/${saleId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return saleId;
  }
);
export const deleteSaleFieldOption = createAsyncThunk(
  "employee/deleteSaleFieldOption",
  async (optionId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/fields/${optionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return optionId;
  }
);

// Add a new employee
export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.post(`${apiUrl}/users`, employeeData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);
export const updateSale = createAsyncThunk(
  "employee/updateSale",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const { _id, ...data } = employeeData;
    const response = await axios.patch(`${apiUrl}/sales/${_id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data;
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: [],
    allEmployees: [],   // Just to fetch and save required fields
    departments: [],
    designations: [],
    estimates: [],
    total: 0,
    status: "idle",
    error: null,
  }, 
  reducers: {
    setDesignations: (state, action) => {
      state.designations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employees = action.payload.employees;
        state.total = action.payload.total;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allEmployees = action.payload.allEmployees;
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.departments = action.payload || [];
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || 'Failed to fetch departments';
      })
      .addCase(fetchDesignations.fulfilled, (state, action) => {
        state.designations = action.payload;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.employees.push(action.payload);
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          (emp) => emp._id === action.payload._id
        );
        if (index !== -1) {
          state.employees[index] = action.payload;
        }
      })
      .addCase(updateEmployeeStatus.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          (emp) => emp._id === action.payload._id
        );
        if (index !== -1) {
          state.employees[index].status = action.payload.status;
        }
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.employees = state.employees.filter(
          (emp) => emp._id !== action.payload
        );
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          (dept) => dept?._id !== action.payload
        );
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        const index = state.departments.findIndex(
          (dept) => dept._id === action.payload._id
        );
        if (index !== -1) {
          state.departments[index] = action.payload;
        }
      })
      .addCase(deleteDesignations.fulfilled, (state, action) => {
        state.designations = state.designations.filter(
          (desig) => desig._id !== action.payload
        );
      })
      .addCase(deleteSale.fulfilled, (state, action) => {
        state.estimates = state.estimates.filter(
          (est) => est._id !== action.payload
        );
      })
      .addCase(deleteSaleFieldOption.fulfilled, (state, action) => {
        state.estimates = state.estimates.filter(
          (est) => est._id !== action.payload
        );
      })
      .addCase(updateDesignations.fulfilled, (state, action) => {
        const index = state.designations.findIndex(
          (desig) => desig._id === action.payload._id
        );
        if (index !== -1) {
          state.designations[index] = action.payload;
        }
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        const index = state.estimates.findIndex(
          (est) => est._id === action.payload._id
        );
        if (index !== -1) {
          state.estimates[index] = action.payload;
        }
      });
  },
});

export const { setDesignations } = employeeSlice.actions;
export default employeeSlice.reducer;
