// src/slices/saleSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { objectToQueryParams } from "../utils";

const base_url = `${process.env.REACT_APP_BASE_URL}`;

// Fetch all sales
export const fetchAllSales = createAsyncThunk(
  "sale/fetchAllSales",
  async ({ page, limit, filter }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(
        `${base_url}/api/sales/?page=${page}&limit=${limit}&${objectToQueryParams(
          filter
        )}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return {
        sales: response?.data?.data,
        total: response?.data?.total,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch sales by user ID
export const fetchSalesByUserId = createAsyncThunk(
  "sale/fetchSalesByUserId",
  async (userId, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${base_url}/api/sales/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Create a new sale
export const createSale = createAsyncThunk(
  "sale/createSale",
  async (saleData, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.post(`${base_url}/api/sales`, saleData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Update an sale
export const updateSale = createAsyncThunk(
  "sale/updateSale",
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;

      const response = await axios.patch(
        `${base_url}/api/sales/${data.get("_id")}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

// Update an sale
export const updateMultipleSales = createAsyncThunk(
  "sale/updateMultipleSales",
  async ({ids=[], field, value }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;

      const response = await axios.patch(
        `${base_url}/api/sales`,
        {ids, field, value},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

// Delete an sale
export const deleteSale = createAsyncThunk(
  "sale/deleteSale",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      await axios.delete(`${base_url}/api/sales/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const saleSlice = createSlice({
  name: "sale",
  initialState: {
    sales: [],
    total: 0,
    loading: false,
    status: {
      fetchAllSales: "idle",
      fetchByUserId: "idle",
      createSale: "idle",
      updateSale: "idle",
      updateMultipleSales: "idle",
      deleteSale: "idle",
    },
    error: {
      fetchAllSales: null,
      fetchByUserId: null,
      createSale: null,
      updateSale: null,
      updateMultipleSales: null,
      deleteSale: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all sales
      .addCase(fetchAllSales.pending, (state) => {
        state.status.fetchAllSales = "loading";
        state.loading = true;
      })
      .addCase(fetchAllSales.fulfilled, (state, action) => {
        state.status.fetchAllSales = "succeeded";
        state.loading = false;
        state.sales = action.payload.sales;
        state.total = action.payload.total;
      })
      .addCase(fetchAllSales.rejected, (state, action) => {
        state.status.fetchAllSales = "failed";
        state.loading = false;
        state.error.fetchAllSales = action.payload;
      })

      // Fetch sales by user ID
      .addCase(fetchSalesByUserId.pending, (state) => {
        state.status.fetchByUserId = "loading";
      })
      .addCase(fetchSalesByUserId.fulfilled, (state, action) => {
        state.status.fetchByUserId = "succeeded";
        state.sales = action.payload;
      })
      .addCase(fetchSalesByUserId.rejected, (state, action) => {
        state.status.fetchByUserId = "failed";
        state.error.fetchByUserId = action.payload;
      })

      // Create a new sale
      .addCase(createSale.pending, (state) => {
        state.status.createSale = "loading";
      })
      .addCase(createSale.fulfilled, (state, action) => {
        state.status.createSale = "succeeded";
        state.sales.push(action.payload);
      })
      .addCase(createSale.rejected, (state, action) => {
        state.status.createSale = "failed";
        state.error.createSale = action.payload;
      })

      // Update an sale
      .addCase(updateSale.pending, (state) => {
        state.status.updateSale = "loading";
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        state.status.updateSale = "succeeded";
        const index = state.sales.findIndex(
          (sale) => sale._id === action.payload._id
        );
        if (index !== -1) {
          state.sales[index] = action.payload;
        }
      })
      .addCase(updateSale.rejected, (state, action) => {
        state.status.updateSale = "failed";
        state.error.updateSale = action.payload;
      })

       // Update Multiple sales
       .addCase(updateMultipleSales.pending, (state) => {
        state.status.updateMultipleSales = "loading";
      })
      .addCase(updateMultipleSales.fulfilled, (state, action) => {
        state.status.updateMultipleSales = "succeeded";
      })
      .addCase(updateMultipleSales.rejected, (state, action) => {
        state.status.updateMultipleSales = "failed";
      })

      // Delete sale
      .addCase(deleteSale.pending, (state) => {
        state.status.deleteSale = "loading";
      })
      .addCase(deleteSale.fulfilled, (state, action) => {
        state.status.deleteSale = "succeeded";
        state.sales = state.sales.filter((sale) => sale._id !== action.payload);
      })
      .addCase(deleteSale.rejected, (state, action) => {
        state.status.deleteSale = "failed";
        state.error.deleteSale = action.payload;
      });
  },
});

export default saleSlice.reducer;
