import React, { useEffect } from "react";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../hooks/utils/Error/Error";
import { fetchUserReport } from "../../../../store/slices/CallSlice";

const UserTable = ({
  user, date
}) => {
  const dispatch = useDispatch();
  const { userReport: apiData } = useSelector((state) => state?.calls || {});

  useEffect(() => {
    dispatch(fetchUserReport({ user, date }));
  }, [dispatch, user, date]);

  const reportData = apiData || [];

  const salesElements = reportData?.map((call, index) => ({
    key: index,
    id: call._id,
    hour: call?.hour || call?.hour === 0 ? `${call?.hour}:00` : "Summary",
    totalCalls: call.totalCalls || 0,
    exemptedCalls: call.exemptedCalls || 0,
    deadAirCalls: call.deadAirCalls || 0,
    transactionCalls: call.transactionCalls || 0,
    acceptedTransactions: call.acceptedTransactions || 0,
    declinedTransactions: call.declinedTransactions || 0,
    acceptedTransactionTotal:
      "$" + (call.acceptedTransactionTotal?.toFixed(2) || "0"),
    declinedTransactionTotal:
      "$" + (call.declinedTransactionTotal?.toFixed(2) || "0"),
  }));

  // Define the columns for the table
  const columns = [
    {
      title: "Hour",
      dataIndex: "hour",
      // fixed: 'left',
      onCell: (record, rowIndex) => ({
        style: {
          backgroundColor: rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff",
          // zIndex: 1,
          borderRight: "2px solid #d9d9d9",
        },
      }),
    },
    {
      title: "Call Answered Total",
      dataIndex: "totalCalls",
    },
    {
      title: "Exempted Calls Total",
      dataIndex: "exemptedCalls",
    },
    {
      title: "Dead Air(s)",
      dataIndex: "deadAirCalls",
    },
    {
      title: "Accepted Transaction Total",
      dataIndex: "transactionCalls",
    },
    {
      title: "Declined Transactions Count",
      dataIndex: "declinedTransactions",
    },
    {
      title: "Accepted Transactions Count",
      dataIndex: "acceptedTransactions",
    },
    {
      title: "Accepted Transactions Total ($)",
      dataIndex: "acceptedTransactionTotal",
    },
    {
      title: "Declined Transactions Total ($)",
      dataIndex: "declinedTransactionTotal",
      sorter: (a, b) => a.declinedTransactionTotal - b.declinedTransactionTotal,
    },
  ];

  const isLoading = false;
  const isError = false;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={isLoading || isError ? [] : salesElements}
            className="table table-striped custom-table mb-0"
            locale={{
              emptyText: isLoading ? (
                <Loading />
              ) : isError ? (
                <Error404 />
              ) : (
                "No Data"
              ),
            }}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
