import React, { useState, useEffect } from 'react';
import { List, Avatar, Input, Tabs, Button, Modal, message, Badge, Typography } from 'antd';
import { ExclamationCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosSearch } from "react-icons/io";
import { formatDistanceToNow } from 'date-fns';
import { MdOutlineDeleteSweep } from "react-icons/md";
const { Search } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;
const { confirm } = Modal;

const ChatSidebar = ({ chats = [], onChatSelect, selectedChat, user, onDeleteChat }) => {
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState('direct');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobileView(mobile);
      if (!mobile) {
        setSidebarVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Ensure chats is an array and filter out any undefined/null items
  const validChats = Array.isArray(chats) ? chats.filter(Boolean) : [];

  const filteredChats = validChats.filter(chat => {
    const searchLower = searchText?.toLowerCase() || '';
    const otherUser = chat?.users?.find(u => {
      // In a group chat, we don't need to find the other user
      if (chat?.isGroupChat) return false;
      
      // For 1-on-1 chats, find the user that's not the current user
      return u?._id !== user?.id;
    });
    
    const chatName = chat?.isGroupChat 
      ? chat?.chatName?.toLowerCase()
      : otherUser ? `${otherUser?.firstName} ${otherUser?.lastName}`.toLowerCase() : '';
    return chatName?.includes(searchLower);
  });

  // Filter chats based on tab
  const displayedChats = filteredChats.filter(chat => 
    
    (activeTab === 'direct' && !chat?.isGroupChat) ||
    (activeTab === 'groups' && chat?.isGroupChat)
  );

  const showDeleteConfirm = (chatId, isGroupChat) => {
    confirm({
      title: isGroupChat ? 'Are you sure you want to left the group?': 'Are you sure you want to delete this chat?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleDeleteChat(chatId);
      },
    });
  };

  const handleDeleteChat = async (chatId) => {
    try {
      onDeleteChat(chatId); // Just notify parent, let parent handle the API call
    } catch (error) {
      console.error('Error deleting chat:', error);
      message.error('Failed to delete chat');
    }
  };

  const handleChatSelect = (chat) => {
    onChatSelect(chat);
    if (isMobileView) {
      setSidebarVisible(false);
    }
  };

  const renderChatItem = (chat) => {
    const isDeletedForUser = chat?.deletedFor?.includes(user?._id); // Check if the chat is deleted for the user
    const otherUsers = chat?.users?.filter((u) => u._id !== user?._id); // Exclude current user
  
    const chatName = chat.isGroupChat
      ? chat.chatName
      : otherUsers?.map((u) => `${u.firstName} ${u.lastName}`).join(', ') || "Unknown User";
  
    const lastMessage = chat?.latestMessage
      ? chat.latestMessage.content
      : "No messages yet";
      const hasUnreadMessages = chat?.unreadCount > 0; // Define hasUnreadMessages
    return (
      <List.Item
        key={chat?._id}
        className={`${selectedChat?._id === chat?._id ? 'selected' : ''} ${hasUnreadMessages ? 'unread' : ''}`}
        onClick={() => handleChatSelect(chat)}
        actions={[
          <Button
            type="text"
            icon={<MdOutlineDeleteSweep  />}
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              showDeleteConfirm(chat?._id, chat?.isGroupChat);
            }}
          />
        ]}
      >
        <List.Item.Meta
          avatar={
            <Badge
              count={isDeletedForUser ? 'Deleted' : 0} // Add 'D' badge for deleted chats
              style={{
                backgroundColor: isDeletedForUser ? 'red' : 'transparent',
              }}
            >
              <Avatar
                size={30}
                icon={<BsPersonCircle size={24} />}
                src={chat.isGroupChat ? chat.groupAvatar : otherUsers[0]?.profilePicture}
              />
            </Badge>
          }
          title={
            <div className="chat-item-header">
              <div className="chat-name-container">
                <span>{chatName}</span>
                {hasUnreadMessages && (
                  <Badge 
                    count={chat.unreadCount} 
                    style={{ 
                      backgroundColor: '#1890ff',
                      marginLeft: '8px'
                    }} 
                  />
                )}
              </div>
            </div>
          }
          description={
            <div style={{ 
              display: 'flex', 
              alignItems: 'center',
              color: hasUnreadMessages ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
              fontWeight: hasUnreadMessages ? 500 : 400
            }}>
              <Text type="secondary" ellipsis>
                {lastMessage}
              </Text>
              {chat.latestMessage && chat.latestMessage.createdAt && (
                <Text type="secondary" className="message-time" style={{ marginLeft: 'auto' }}>
                  {(() => {
                    try {
                      return formatDistanceToNow(new Date(chat.latestMessage.createdAt), { addSuffix: true });
                    } catch (error) {
                      console.error('Error formatting date:', error);
                      return '';
                    }
                  })()}
                </Text>
              )}
            </div>
          }
        />
      </List.Item>
    );
  };

  return (
    <div className={`chat-sidebar ${isMobileView ? (sidebarVisible ? 'show' : '') : ''}`}>
      {isMobileView && (
        <Button
          type="text"
          icon={sidebarVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          onClick={toggleSidebar}
          className="sidebar-toggle"
          style={{
            position: 'absolute',
            right: '-40px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: '#fff',
            boxShadow: '2px 0 8px rgba(0,0,0,0.15)',
            borderRadius: '0 4px 4px 0',
            width: 40,
            height: 40,
            zIndex: 1
          }}
        />
      )}
      <div className="chat-sidebar-wrapper">
        <div className="sidebar-header">
         
          <Search
            placeholder="Search chats..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            prefix={<IoIosSearch size={16} color='pink'/>}
            allowClear
            className="search-input"
          />
          <Tabs 
            activeKey={activeTab} 
            onChange={setActiveTab}
            className="chat-tabs"
          >
            <TabPane tab="Direct Messages" key="direct" />
            <TabPane tab="Groups" key="groups" />
          </Tabs>
        </div>
        <div className="sidebar-content">
          <List
            dataSource={displayedChats}
            renderItem={renderChatItem}
            locale={{
              emptyText: 'No conversations found'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
