import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Table, Pagination } from "antd";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DateFormat from "../../../hooks/utils/DateFormat";
import useFetch from "../../../hooks/api/fetchHook";
import UsePostData from "../../../hooks/api/PostHook";
import Loading from "../../../hooks/utils/Loading/Loading";
import { toast } from "react-toastify";

const AttendanceEmployee = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredAttendances, setFilteredAttendances] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);
  const popupRef = useRef(null);
  const [loadData, setLoadData] = useState(false);
  const userInfo = useSelector((state) => state?.user?.userDetails?.data?.user);
  const [todayAttendance, setTodayAttendance] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [visibilityChanged, setVisibilityChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setVisibilityChanged((prev) => !prev);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const { userToken, userId } = useSelector((state) => ({
    userToken: state?.auth?.userToken,
    userId: state?.auth?.userInfo?.id,
  }));

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { postData: postPunchIn } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/punch-in`
  );
  const { postData: postPunchOut } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/punch-out`
  );

  const { apiData } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/${userId}?page=${currentPage}&limit=${pageSize}`,
    options,
    loadData
  );

  const allDates =
    apiData?.data?.attendances?.map((attendance) => attendance?.date) || [];

  function timeState() {
    if (!userInfo?.startTime || !userInfo?.endTime) {
      return { state: 0 };
    }

    let currentTime = new Date();
    const shiftStartTime = new Date(userInfo.startTime);
    const shiftEndTime = new Date(userInfo.endTime);

    const startTime = new Date(currentTime);
    startTime.setUTCHours(
      shiftStartTime.getUTCHours(),
      shiftStartTime.getUTCMinutes(),
      0,
      0
    );

    if (currentTime < startTime) {
      startTime.setUTCDate(startTime.getUTCDate() - 1);
    }

    let endTime = new Date(startTime);
    endTime.setUTCHours(
      shiftEndTime.getUTCHours(),
      shiftEndTime.getUTCMinutes(),
      0,
      0
    );

    const timeDifference = endTime - startTime;
    if (timeDifference < 0) {
      endTime.setUTCDate(endTime.getUTCDate() + 1);
    }

    const cutoffTime = new Date(endTime);
    cutoffTime.setUTCHours(cutoffTime.getUTCHours() + 1);

    if (currentTime < startTime) {
      return { state: 1, startTime, endTime, currentTime, cutoffTime };
    } else if (currentTime >= startTime && currentTime <= endTime) {
      return { state: 2, startTime, endTime, currentTime, cutoffTime };
    } else if (currentTime > endTime && currentTime <= cutoffTime) {
      return { state: 3, startTime, endTime, currentTime, cutoffTime };
    }
    return { state: 4, startTime, endTime, currentTime, cutoffTime };
  }

  function isTodayAttendance(time) {
    if (!userInfo.startTime || !userInfo.endTime) {
      return false;
    }

    let attendanceTime = new Date(time);
    let currentTime = new Date();
    const shiftStartTime = new Date(userInfo.startTime);

    const startTime = new Date(currentTime);
    startTime.setUTCHours(
      shiftStartTime.getUTCHours(),
      shiftStartTime.getUTCMinutes(),
      0,
      0
    );

    if (currentTime < startTime) {
      startTime.setUTCDate(startTime.getUTCDate() - 1);
    }

    if (attendanceTime < startTime) {
      return false;
    }
    return true;
  }

  function stopTimer() {
    setTime(0);
    setIsRunning(false);
    clearInterval(timerRef.current);
  }

  function startTimer(time) {
    setTime(time);
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    setIsRunning(true);
  }

  const { formatDateTime } = DateFormat();

  useEffect(() => {
    if (apiData?.data?.attendances) {
      setPageLoading(false);
      setFilteredAttendances(apiData.data.attendances);
      const allAttendance = apiData?.data?.attendances;
      const attendanceLength = allAttendance?.length - 1;
      const attendance = allAttendance[attendanceLength];

      const punchesLength =
        allAttendance[attendanceLength]?.punches?.length - 1;
      const lastPunch =
        allAttendance?.[attendanceLength]?.punches?.[punchesLength];
      const latestDate = allAttendance[attendanceLength]?.date;

      const isToday = isTodayAttendance(latestDate);

      stopTimer();
      if (!isToday) {
        return;
      }

      setTodayAttendance(attendance);

      const currentShift = timeState();
      // Either in shift time or cutoff time
      if (![2, 3].includes(currentShift.state)) {
        return;
      }

      if (lastPunch?.type === "out") {
        return;
      }

      const currentTime = new Date();
      const targetTime = new Date(lastPunch?.time);
      const differenceInSeconds = Math.floor((currentTime - targetTime) / 1000);
      startTimer(attendance.totalHours * 60 + differenceInSeconds);

      const options = {
        timeZone: "Asia/Karachi",
        hour: "2-digit",
        minute: "2-digit",
      };

      if (currentShift.state === 3) {
        toast.error(
          `Shift End At ${currentShift.endTime.toLocaleTimeString(
            "en-US",
            options
          )}, Punch Out`,
          {
            autoClose: false,
          }
        );
        // handlePlayStop(true);
        return;
      }

      if (currentShift.state === 2) {
        popupRef.current = setTimeout(() => {
          toast.error(
            `Shift End At ${currentShift.endTime.toLocaleTimeString(
              "en-US",
              options
            )}, Punch Out`,
            {
              autoClose: false, // Prevent the toast from auto-closing
            }
          );
          // handlePlayStop(true);
        }, currentShift.endTime - currentShift.currentTime);
        return;
      }
    }

    return () => {
      if (popupRef.current) {
        clearTimeout(popupRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData?.data?.attendances, visibilityChanged]);

  const filterAttendances = () => {
    if (!apiData?.data?.attendances) return [];

    const filtered = apiData.data.attendances.filter((attendance) => {
      const attendanceDate = new Date(attendance.date);
      const matchesDate = selectedDate
        ? attendanceDate.getDate() === parseInt(selectedDate)
        : true;
      const matchesMonth = selectedMonth
        ? attendanceDate.getMonth() + 1 === selectedMonth.value
        : true;
      const matchesYear = selectedYear
        ? attendanceDate.getFullYear() === selectedYear.value
        : true;
      return matchesDate && matchesMonth && matchesYear;
    });

    const formattedDate = new Date(
      selectedYear.value,
      selectedMonth.value - 1,
      selectedDate
    ).toISOString();

    setFilteredAttendances(filtered);
  };

  const userElements =
    filteredAttendances?.map((user, index) => {
      const punchesOut = user.punches.filter((punch) => punch.type === "out");
      const lastPunchOut = punchesOut[punchesOut.length - 1];

      return {
        key: index,
        id: index + 1,
        Date: formatDateTime(user.date, "date"),
        Login: formatDateTime(user.punches[0]?.time, "time"),
        Logout: formatDateTime(lastPunchOut?.time, "time"),
        Production: user.Production,
        Break: minutesToHoursFormatted(user.breaks),
      };
    }) || [];

  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split(" ");
    const dayNumber = parseInt(day.replace(/[^0-9]/g, ""), 10);
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const monthNumber = monthMap[month];
    return `${year}-${monthNumber}-${String(dayNumber).padStart(2, "0")}`;
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "Date",
      sorter: (a, b) =>
        new Date(formatDate(a.Date)) - new Date(formatDate(b.Date)),
      defaultSortOrder: "descend",
    },
    {
      title: "Login",
      dataIndex: "Login",
      // sorter: (a, b) => a.Login.localeCompare(b.Login),
    },
    {
      title: "Logout",
      dataIndex: "Logout",
      // sorter: (a, b) => a.Logout.localeCompare(b.Logout),
    },
    {
      title: "Break",
      dataIndex: "Break",
      // sorter: (a, b) => a.Break.localeCompare(b.Break),
    },
  ];

  const handlePlayStop = async (punchOut) => {
    setPageLoading(true);
    if (punchOut) {
      const punchOutData = {
        userId: userId,
      };

      const data = await postPunchOut(punchOutData);
      if (data?.status === "success") {
        toast.success("Punched out");
        setLoadData(!loadData);
      } else {
        setPageLoading(false);
        toast.error(data?.message);
        setVisibilityChanged((prev) => !prev);
      }
    } else {
      const punchInData = {
        userId: userId,
      };

      const data = await postPunchIn(punchInData);
      if (data?.status === "success") {
        toast.success("Punched In");
        setLoadData(!loadData);
      } else {
        setPageLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  function minutesToHoursFormatted(minutes) {
    const seconds = minutes * 60;
    const hours =
      typeof seconds === "number" && !isNaN(seconds)
        ? Math.floor(seconds / 3600)
        : 0;
    const min =
      typeof seconds === "number" && !isNaN(seconds)
        ? Math.floor((seconds % 3600) / 60)
        : 0;
    return `${hours.toString().padStart(2, "0")}:${min
      .toString()
      .padStart(2, "0")}`;
  }

  const formatTime = (seconds) => {
    const secs = Math.floor(seconds % 60);
    const hours =
      typeof seconds === "number" && !isNaN(seconds)
        ? Math.floor(seconds / 3600)
        : 0;
    const min =
      typeof seconds === "number" && !isNaN(seconds)
        ? Math.floor((seconds % 3600) / 60)
        : 0;
    return `${hours.toString().padStart(2, "0")}:${min
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  if (pageLoading) {
    return (
      <div className="loading-effect">
        <Loading />
      </div>
    );
  }

  function fetchTotalHours(minuites) {
    const totalHoursString = minutesToHoursFormatted(minuites) || "00:00";
    const [hours, minutes] = totalHoursString.split(":").map(Number);
    return hours + minutes / 60;
  }

  return (
    <>
      <div className="page-wrapper">
        {/* /Page Header */}
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Attendance"
            title="Main"
            subtitle="Attendance"
            modalClass="col-auto float-end ms-auto"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body">
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">
                      {formatDateTime(todayAttendance.date, "date")}
                    </small>
                  </h5>
                  <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>
                      {formatDateTime(
                        todayAttendance?.punches?.at(-1)?.time,
                        "datetime"
                      )}
                    </p>
                  </div>
                  <div className="punch-info">
                    <div className="punch-hours">
                      <span>
                        {" "}
                        {isRunning
                          ? formatTime(time)
                          : minutesToHoursFormatted(
                              todayAttendance?.totalHours
                            )}
                      </span>
                    </div>
                  </div>
                  <div className="punch-btn-section">
                    <button
                      type="button"
                      className="btn btn-primary punch-btn"
                      disabled={pageLoading}
                      onClick={() => handlePlayStop(isRunning)}
                    >
                      {isRunning ? "Punch Out" : "Punch In"}
                    </button>
                  </div>
                  <div className="statistics">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Break</p>
                          <h6>
                            {minutesToHoursFormatted(todayAttendance?.breaks)}
                            &nbsp; hr
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card att-statistics">
                <div className="card-body">
                  <h5 className="card-title">Statistics</h5>
                  <div className="stats-list">
                    <div className="stats-info">
                      <p>
                        Today
                        <strong>
                          {minutesToHoursFormatted(todayAttendance?.totalHours)}{" "}
                          <small>/ 8 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-primary`}
                          role="progressbar"
                          style={{
                            width: `${Math.min(
                              (fetchTotalHours(todayAttendance.totalHours) /
                                8) *
                                100,
                              100
                            )}%`,
                          }}
                          aria-valuenow={Math.min(
                            (fetchTotalHours(todayAttendance.totalHours) / 8) *
                              100,
                            100
                          )}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>

                    {/* This Week */}
                    <div className="stats-info">
                      <p>
                        This Week
                        <strong>
                          {minutesToHoursFormatted(
                            todayAttendance?.weeklyTotalHours
                          )}{" "}
                          <small>/ 40 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-warning`}
                          role="progressbar"
                          // width: `${Math.min((totalHours / 8) * 100, 100)}%`,
                          style={{
                            width: `${Math.min(
                              (fetchTotalHours(
                                todayAttendance.weeklyTotalHours
                              ) /
                                40) *
                                100,
                              100
                            )}%`,
                          }}
                          aria-valuenow={
                            (fetchTotalHours(todayAttendance.weeklyTotalHours) /
                              40) *
                            100
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>

                    {/* This Month */}
                    <div className="stats-info">
                      <p>
                        This Month
                        <strong>
                          {minutesToHoursFormatted(
                            todayAttendance?.monthlyTotalHours
                          )}{" "}
                          <small>/ 160 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-danger`}
                          role="progressbar"
                          style={{
                            width: `${Math.min(
                              (fetchTotalHours(
                                todayAttendance.monthlyTotalHours
                              ) /
                                160) *
                                100,
                              100
                            )}%`,
                          }}
                          aria-valuenow={
                            (fetchTotalHours(
                              todayAttendance.monthlyTotalHours
                            ) /
                              160) *
                            100
                          }
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today Activity</h5>
                  <ul className="res-activity-list">
                    <div className="res-activity-list-inside position-relative">
                      {todayAttendance?.punches?.length > 0 ? (
                        todayAttendance?.punches
                          .slice()
                          .reverse()
                          .map((item, index) => (
                            <li key={index}>
                              <p className="mb-0">Punch {item.type}</p>
                              <p className="res-activity-time">
                                <i className="fa-regular fa-clock"></i>{" "}
                                {formatDateTime(item?.time, "time")}
                              </p>
                            </li>
                          ))
                      ) : (
                        <p>No activities available.</p>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <AttendanceEmployeeFilter
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              onSearch={filterAttendances}
            />
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={userElements?.length > 0 ? userElements : []}
                    className="table-striped"
                    rowKey={(record) => record.id}
                    pagination={false}
                  />
                  <div
                    className="mt-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      // total={total || 0}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "25", "50", "100"]}
                      showQuickJumper
                      // showTotal={(total) => `Total ${total}`}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          return <span>Previous</span>;
                        }
                        if (type === "next") {
                          return <span>Next</span>;
                        }
                        return originalElement;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceEmployee;
