import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ChatSidebar from './components/ChatSidebar';
import ChatWindow from './components/ChatWindow';
import axios from 'axios';
import { fetchDepartments } from '../../../store/slices/EmployeeSlice';
import './chat.scss';

const { Option } = Select;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Chat = () => {
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(null);
  const [isNewGroupModalVisible, setNewGroupModalVisible] = useState(false);
  const [isNewChatModalVisible, setNewChatModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]); 
  const [form] = Form.useForm();
  const [chatForm] = Form.useForm();
  
  const user = useSelector(state => state?.auth?.userInfo); 
  const userToken = useSelector(state => state.auth.userToken);
  const departments = useSelector(state => state.employee?.departments || []);

  useEffect(() => {
    if (userToken) {
      dispatch(fetchDepartments());
    }
  }, [dispatch, userToken]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const endpoint = user?.role === 'admin'
      ? `${BASE_URL}/api/users?fields=_id lastName firstName employeeId -attendance -performanceReviews` // Admin fetches all users
      : `${BASE_URL}/api/users?department=${user?.department}&fields=_id lastName firstName employeeId -attendance -performanceReviews`; // Others fetch by department

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
       
      
      if (response.data?.status === 'success') {
        // Ensure we're getting the array of users from the correct path
        const usersList = Array.isArray(response.data.data) 
          ? response.data.data 
          : response.data.data?.users || [];
         
        
        // Filter out the current user
        let filteredUsers = usersList.filter(u => u._id !== user?.id);
        
        setUsers(filteredUsers);
      } else {
        console.error('Failed response:', response.data); // Debug log
        message.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  const fetchChats = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/chat`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
       
      if (response.data?.status === 'success' && Array.isArray(response.data?.data)) { 
        setChats(response.data.data);
      } else {
        console.error('Invalid chat data structure:', response.data);
        setChats([]); // Set empty array if no valid data
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
      message.error('Error fetching chats');
      setChats([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      fetchChats();
    }
  }, [userToken]);

  useEffect(() => {
    if (selectedChat) {
      fetchChats();
    }
  }, [selectedChat]);

  useEffect(() => {
    if (isNewChatModalVisible || isNewGroupModalVisible) {
      fetchUsers();
    }
  }, [isNewChatModalVisible, isNewGroupModalVisible]);

  const handleCreateChat = async (values) => {
    try {
      setLoading(true);
      
      // Check if user can chat with selected user (department check for non-admin)
      if (user?.role !== 'admin' && user?.department) {
        const selectedUser = users.find(u => u._id === values.userId);
        if (selectedUser?.department._id !== user.department) {
          message.error('You can only chat with users from your department');
          return;
        }
      }

      // Find the selected user from the users list
      const selectedUser = users.find(u => u._id === values.userId);
      if (!selectedUser) {
        throw new Error('Selected user not found');
      }
 

      // Create a new chat
      const response = await axios.post(`${BASE_URL}/api/chat`, 
        { userId: selectedUser._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
       

      if (response.data.status === 'success') {
        message.success('Chat created successfully');
        // Make sure we have the full chat data with populated users
        const newChat = {
          ...response.data.data,
          users: response.data.data.users.map(u => ({
            ...u,
            firstName: u.firstName || '',
            lastName: u.lastName || ''
          }))
        };
        setSelectedChat(newChat);
        await fetchChats(); // Refresh the chat list
        setNewChatModalVisible(false);
        chatForm.resetFields();
      } else {
        message.error(response.data.message || 'Failed to create chat');
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      message.error(error.response?.data?.message || 'Error creating chat');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateGroup = async (values) => {
    try {
      setLoading(true);
      
      // Add current user to the group members
      const allUsers = [...values.users];
      if (!allUsers.includes(user?.id)) {
        allUsers.push(user?.id);
      }

      const response = await axios.post(`${BASE_URL}/api/chat/group`,
        {
          name: values.name,
          users: JSON.stringify(allUsers),
          department: values.department
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.status === 'success') {
        message.success('Group created successfully');
        setSelectedChat(response.data.data);
        setNewGroupModalVisible(false);
        form.resetFields();
        await fetchChats(); // Refresh chat list
      } else {
        throw new Error('Failed to create group - no response data');
      }
    } catch (error) {
      console.error('Error creating group:', error);
      message.error(error.response?.data?.message || 'Failed to create group');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      if (!chatId) {
        console.error('Invalid chat ID');
        return;
      }

      // First update UI optimistically
      const updatedChats = chats?.filter(chat => chat?._id !== chatId);
      setChats(updatedChats || []);
      
      if (selectedChat?._id === chatId) {
        setSelectedChat(null);
      }

      const response = await fetch(`${BASE_URL}/api/chat/${chatId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete chat');
      }

      const data = await response.json();
      if (data?.status === 'success') {
        message.success('Chat deleted successfully');
      } else {
        throw new Error(data?.message || 'Failed to delete chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      message.error(error.message || 'Failed to delete chat');
      // Revert UI on error
      fetchChats();
    }
  };

  const handleChatUpdate = (updatedChat) => {
    setChats(prevChats => {
      const index = prevChats.findIndex(chat => chat._id === updatedChat._id);
      if (index !== -1) {
        const newChats = [...prevChats];
        newChats[index] = updatedChat;
        return newChats;
      }
      return prevChats;
    });
    setSelectedChat(updatedChat);
  };

  const handleChatSelect = (chat) => {
    if (!chat?._id) {
      console.error('Invalid chat selected:', chat);
      return;
    } 
    setSelectedChat(chat);
  };

  return (
    <Container fluid className="chat-container p-0">
      <Row className="chat-row">
        <Col xs={12} md={4} lg={3} className="chat-sidebar-col">
          <div className="chat-sidebar-wrapper">
            <div className="sidebar-header">
              <div className="d-flex justify-content-center gap-1 button-container">
                <Button 
                  className='bg-secondary text-white font-weight-bold' 
                  icon={<FaPlus />}
                  onClick={() => setNewChatModalVisible(true)} 
                >
                  Start New Chat
                </Button>
                <Button
                  type="default"
                  onClick={() => setNewGroupModalVisible(true)} 
                  icon={<FaPlus />}
                >
                  Create Group
                </Button>
              </div>
            </div>
            <div className="sidebar-content">
              <ChatSidebar 
                chats={chats}
                onChatSelect={handleChatSelect} 
                selectedChat={selectedChat}
                user={user}
                onStartNewChat={() => setNewChatModalVisible(true)}
                onCreateGroup={() => setNewGroupModalVisible(true)}
                onDeleteChat={handleDeleteChat}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} md={8} lg={9} className="chat-main-col">
          <ChatWindow 
            selectedChat={selectedChat} 
            user={user} 
            userToken={userToken}
            onChatUpdate={handleChatUpdate}
            users={users} // Add this prop
          />
        </Col>
      </Row>

      <Modal
        title="New Message"
        open={isNewChatModalVisible}
        onCancel={() => {
          setNewChatModalVisible(false);
          chatForm.resetFields();
        }}
        onOk={() => chatForm.submit()}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form
          form={chatForm}
          layout="vertical"
          onFinish={handleCreateChat}
        >
          <Form.Item
            name="userId"
            label="Select User"
            rules={[{ required: true, message: 'Please select a user' }]}
            help="Search by name or email"
          >
            <Select
              placeholder="Search and select user"
              showSearch
              loading={loading}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
              options={users.map(user => {
               // Debug each user during mapping
                return {
                  value: user._id,
                  label: user.name || `${user.firstName || ''} ${user.lastName || ''}`
                };
              })}
              notFoundContent={loading ? 'Loading users...' : 'No users found'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Create New Group"
        open={isNewGroupModalVisible}
        onCancel={() => {
          setNewGroupModalVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateGroup}
        >
          <Form.Item
            name="name"
            label="Group Name"
            rules={[{ required: true, message: 'Please enter group name' }]}
          >
            <Input placeholder="Enter group name" />
          </Form.Item>
          <Form.Item
            name="department"
            label="Department"
            rules={[{ required: true, message: 'Please select department' }]}
            initialValue={user?.role !== 'admin' ? user?.department : undefined}
          >
            <Select 
              placeholder="Select department"
              loading={loading}
              disabled={user?.role !== 'admin'}
            >
              {departments.map(dept => (
                <Option key={dept._id} value={dept._id}>
                  {dept.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="users"
            label="Select Users"
            rules={[{ required: true, message: 'Please select at least one user' }]}
            help="Search by name or email"
          >
            <Select
              mode="multiple"
              placeholder="Search and select users"
              showSearch
              loading={loading}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={users.map(u => ({
                value: u._id,
                label: u.firstName && u.lastName 
                  ? `${u.firstName} ${u.lastName}`
                  : u.email || 'Unnamed User'
              }))}
              notFoundContent={loading ? 'Loading users...' : 'No users found'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
};

export default Chat;


