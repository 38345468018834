import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DailyTable from "./DailyTable.jsx";
import Breadcrumbs from "../../../../components/Breadcrumbs.jsx";
import DeleteModal from "../../../../components/modelpopup/DeleteModal.jsx";

const getAdjustedDate = () => {
  const now = new Date();
  const sixAM = new Date();
  sixAM.setHours(6, 0, 0, 0);

  if (now < sixAM) {
    sixAM.setDate(sixAM.getDate() - 1);
  }
  return sixAM;
};
const DailyReport = () => {
  const [startDate, setStartDate] = useState(getAdjustedDate());
  const [endDate, setEndDate] = useState(getAdjustedDate());

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Report"
            title="Main"
            subtitle="Report"
            modal="#"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div className={`input-block mb-3 form-focus focused`}>
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    onChange={(date) => setStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className={`input-block mb-3 form-focus focused`}>
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={endDate}
                    onSelect={(date) => setEndDate(date)}
                    onChange={(date) => setEndDate(date)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
          </div>

          <DailyTable startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default DailyReport;
