export const SidebarData = [
  {
    tittle: "Dashboard",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Admin Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/admin-dashboard",
        icon: "la la-user-tie",
        role: ["admin", "hr", "manager"],
      },
      {
        menuValue: "Employee Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-dashboard",
        icon: "la la-user",
        role: ["employee", "supervisor"],
      },
    ],
  },
  {
    tittle: "Communication",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Chat",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/chat",
        icon: "la la-comments",
        role: ["admin", "hr", "manager", "employee", "supervisor"],
      },
    ],
  },
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    role: ["admin", "hr", "manager"], // Title on role based 
    menu: [
      {
        menuValue: "All Employees",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employees",
        icon: "la la-user",
        role: ["admin", "hr", "manager"],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/admin-leaves",
        icon: "la la-calendar-check-o",
        role: ["admin", "hr"],
      },
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/admin-attendance",
        icon: "la la-clock-o",
        role: ["admin", "hr", "manager"], 
      },
      {
        menuValue: "Departments",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/departments",
        icon: "la la-building-o",
        role: ["admin", "hr"],
      },
      {
        menuValue: "Designations",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/designations",
        icon: "la la-briefcase",
        role: ["admin", "hr"],
      },
      {
        menuValue: "Assets",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/assets",
        icon: "la la-laptop",
        role: ["admin", "hr", "manager"], 
      },
      {
        menuValue: "Holidays",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/holidays",
        icon: "la la-umbrella-beach",
        role: ["admin", "hr"],
      },
      {
        menuValue: "Upwork",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["admin"], 

        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Users",
            route: "/upwork-users",
          },
          {
            menuValue: "Bidding",
            route: "/bids",
          },
          {
            menuValue: "Projects",
            route: "/projects",
          },
          {
            menuValue: "Project Tracking",
            route: "/project-tracking",
          },
          {
            menuValue: "Upwork Agents",
            route: "/agent-statistics",
          },
          {
            menuValue: "Upwork Bidders",
            route: "/bidder-statistics",
          },
        ],
      },
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["admin", "manager"],

        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Sales",
            route: "/sales",
          },
          {
            menuValue: "Calls",
            route: "/calls",
          },
          {
            menuValue: "Fields",
            route: "/fields",
          },
        ],
      },
      {
        menuValue: "Sales Report",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["admin", "manager"],
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Daily Report",
            route: "/daily-report",
          },
          {
            menuValue: "User Report",
            route: "/user-report",
          },
        ],
      },      
    
      // {
      //   menuValue: "Payroll",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   role: ["admin"],

      //   icon: "la la-money",
      //   subMenus: [
      //     {
      //       menuValue: "Employee Salary",
      //       route: "/employee-salary",
      //     },
      //     {
      //       menuValue: "Payslip",
      //       route: "/salary-view",
      //     },
      //     {
      //       menuValue: "Payroll Items",
      //       route: "/payroll-items",
      //     },
      //   ],
      // },
    ],
  },
  {
    tittle: "Employee",
    showAsTab: false,
    separateRoute: false,
    role: ["employee", "hr", "manager", "supervisor"],
    menu: [
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/attendance-employee",
        icon: "la la-clock-o",
        role: ["employee", "hr", "manager", "supervisor"],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/leaves-employee",
        icon: "la la-calendar-check-o",
        role: ["employee", "hr", "manager", "supervisor"],
      },
      {
        menuValue: "Upwork",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "hr", "manager", "supervisor"],
        upworkRole: ["manager"],  
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Users",
            route: "/upwork-users",
          },
          {
            menuValue: "Bidding",
            route: "/bids",
          },
          {
            menuValue: "Projects",
            route: "/projects",
          },
          {
            menuValue: "Project Tracking",
            route: "/project-tracking",
          },
          {
            menuValue: "Upwork Agents",
            route: "/agent-statistics",
          },
          {
            menuValue: "Upwork Bidders",
            route: "/bidder-statistics",
          },
        ],
      },
      {
        menuValue: "Upwork",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "manager", "supervisor"],
        upworkRole: ["floor manager"],  
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Users",
            route: "/upwork-users",
          },
          {
            menuValue: "Bidding",
            route: "/bids",
          },
          {
            menuValue: "Projects",
            route: "/projects",
          },
        ],
      },
      {
        menuValue: "Upwork",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee"],
        upworkRole: ["bidder"],  
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Bidding",
            route: "/bids",
          },
          {
            menuValue: "Projects",
            route: "/projects",
          },
        ],
      },
      {
        menuValue: "Upwork",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee"],
        upworkRole: ['agent'], 
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Projects",
            route: "/projects",
          },
          {
            menuValue: "Project Tracking",
            route: "/project-tracking",
          },
        ],
      },
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "supervisor"],
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Sales",
            route: "/sales",
          },
          {
            menuValue: "Calls",
            route: "/calls",
          },
          {
            menuValue: "Fields",
            route: "/fields",
          },
        ],
      },
      {
        menuValue: "Sales Report",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "supervisor"],
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Daily Report",
            route: "/daily-report",
          },
          {
            menuValue: "User Report",
            route: "/user-report",
          },
        ],
      }
    ],
  },
];
