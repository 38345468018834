import React, { useState, useEffect } from "react";
import axios from "axios";
import Charts from "./charts";
import Reports from "./Reports";
import Statistics from "./statistics";
import Holiday from "./holiday";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../../../store/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { base_url } from "../../../../../base_urls";

const AdminDashboard = () => {
  // const [users, setUsers] = useState([""]);

  const [reload, setReload] = useState(false);
  const { userDetails } = useSelector((state) => state?.user);
  const status = userDetails?.data?.user?.status;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   axios.get(base_url + "/api/dash.json").then((res) => setUsers(res.data));
  // }, []);

  if (status === "disabled") {
    toast.error("This user has been disabled", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: "colored",
    });
    dispatch(logout());
    navigate("/");
  }

  const handleReload = () => {
    setReload((prevReload) => !prevReload);
  };

  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    fontSize: "16px",
    color: "#007bff",
    backgroundColor: "#f8f9fa",
    border: "2px solid #007bff",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const hoverStyles = {
    backgroundColor: "#007bff",
    color: "#fff",
  };

  const handleMouseEnter = (e) => {
    Object.assign(e.currentTarget.style, hoverStyles);
  };

  const handleMouseLeave = (e) => {
    Object.assign(e.currentTarget.style, buttonStyles);
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Welcome Admin!"
            title="Dashboard"
            subtitle="Admin Dashboard"
          />
          {/* /Page Header */}
          {/* <div className="row">
            {Array.isArray(users) && users?.length > 0 ? (
              users.map((item, index) => (
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                  key={index}
                >
                  <div className="card dash-widget">
                    <div className="card-body">
                      <span className={`dash-widget-icon ${item?.icon}`} />
                      <div className="dash-widget-info">
                        <h3>{item?.number}</h3>
                        <span>{item?.label}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div> */}
          {/* /Charts */}
          {/* /Charts */}
          {/* <Reports /> */}
          <button
            onClick={handleReload}
            className="reload-button mb-3 ms-auto"
            style={buttonStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="la la-sync"></i>
          </button>
          <Statistics key={reload ? "statistics-reload" : "statistics"} />
          <Holiday />
          <Charts />
          {/* <div className="row">
            <InvoiceTable />
            <PaymentTable />
          </div> */}

          {/* <div className="row">
            <ClientTable />
            <RecentTable />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
