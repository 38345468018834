import React from "react";
import Select from "react-select";

const AttendanceEmployeeFilter = ({
  selectedDate,
  setSelectedDate,
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  onSearch, // Accept the onSearch prop
}) => {
  const monthOptions = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];

  const yearOptions = [
    { value: 2024, label: "2024" },
    { value: 2023, label: "2023" },
    { value: 2022, label: "2022" },
    { value: 2021, label: "2021" },
    { value: 2020, label: "2020" },
    { value: 2019, label: "2019" },
    { value: 2018, label: "2018" },
  ];

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cb6ce6"
        : state.isFocused
        ? "#f8defff5"
        : undefined,
      color: state.isSelected ? "#fff" : undefined,
      "&:active": {
        backgroundColor: "#f8defff5",
      },
    }),
  };

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            <input
              type="number"
              value={selectedDate}
              onChange={(e) => {
                if (e.target.value.length <= 2) {
                  setSelectedDate(e.target.value);
                }
              }}
              className="form-control floating"
            />
            <label className="focus-label">Date</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            {/* <Select
              value={selectedMonth}
              onChange={setSelectedMonth}
              styles={dropdownStyle}
            /> */}
            <Select
              name="selectMonth"
              options={monthOptions}
              placeholder="Select Month"
              styles={dropdownStyle}
              defaultValue={() =>
                monthOptions.find((option) => option.value === selectedMonth)
              }
              onChange={setSelectedMonth}
              required
            />
            <label className="focus-label">Select Month</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            <Select
              value={selectedYear}
              onChange={setSelectedYear}
              options={yearOptions}
              placeholder="Select Year"
              styles={dropdownStyle}
            />
            <label className="focus-label">Select Year</label>
          </div>
        </div>
        <div className="col-sm-3">
          <button
            type="button"
            className="btn btn-success btn-block w-100"
            onClick={onSearch} // Call the search function when clicked
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default AttendanceEmployeeFilter;
