import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar_02 } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { toast } from "react-toastify";
import Select from "react-select";
import { updateEmployee } from "../../../store/slices/EmployeeSlice";
import useFetch from "../../../hooks/api/fetchHook";

const EmployeeProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);
  const departmentList = useSelector((state) => state?.employee?.departments);
  const [isRefetchRequired, setIsRefetchRequired] = useState(false);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData: userDetail } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
    options
  );
  const [formData, setFormData] = useState(userDetail?.data?.user || {});

  const { apiData: designationList = {} } = useFetch(
    formData?.department?._id
      ? `${process.env.REACT_APP_BASE_URL}/api/designations/department/${formData?.department?._id}`
      : "",
    options,
    formData?.department
  );

  useEffect(() => {
    setFormData(userDetail?.data?.user || {});
  }, [userDetail]);

  const departmentData = departmentList.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const designationData = designationList?.data?.designations.map((item) => ({
    value: item._id,
    label: item.title,
  }));

  const handleChange = async (field, value) => {
    try {
      const response = await dispatch(
        updateEmployee({
          id,
          [field]: value?.value,
          ...(field === "department" ? { designation: null } : {}),
        })
      );

      if (response?.payload?.status === "success") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response?.payload?.data?.user,
          [field]: value?.value,
          ...(field === "department" ? { designation: null } : {}),
        }));
        setIsRefetchRequired(true);
        toast.success(`${field} updated successfully!`);
      } else {
        toast.error("Failed to update. Please try again.");
      }
    } catch (error) {
      console.error("Error updating field:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  useEffect(() => {
    const fetchLatestData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFormData(data?.data?.user || {});
        } else {
          console.error("Failed to fetch updated user data");
        }
      } catch (error) {
        console.error("Error refetching data for label:", error);
      } finally {
        setIsRefetchRequired(false);
      }
    };

    if (isRefetchRequired) {
      fetchLatestData();
    }
  }, [isRefetchRequired, id, userToken]);

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cb6ce6"
        : state.isFocused
        ? "#f8defff5"
        : undefined,
      color: state.isSelected ? "#fff" : undefined,
      "&:active": {
        backgroundColor: "#f8defff5",
      },
    }),
  };

  const {
    firstName,
    lastName,
    email,
    role,
    phone,
    gender,
    dateOfBirth,
    image,
    employeeId,
    address,
  } = formData;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee"
            title="Main"
            subtitle="Profile"
            modalClass="col-auto float-end ms-auto"
            modal="#add_indicator"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={image || Avatar_02} alt="User avatar" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {firstName} {lastName}
                            </h3>
                            <h6 className="text-muted">
                              Role: {role || "N/A"}
                            </h6>
                            <div className="staff-id">
                              Employee ID: {employeeId || "N/A"}
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Designation{" "}
                                  <span className="text-danger">*</span>{" "}
                                  {formData?.designation?.title || "N/A"}
                                </label>
                                <Select
                                  options={designationData}
                                  value={designationData?.find(
                                    (option) =>
                                      option.value ===
                                        formData.designation?._id || null
                                  )}
                                  placeholder={
                                    formData?.designation?.title ||
                                    "Select Designation"
                                  }
                                  styles={{
                                    ...dropdownStyle,
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: state.isSelected
                                        ? "#cb6ce6"
                                        : state.isFocused
                                        ? "#f8defff5"
                                        : undefined,
                                      color: state.isSelected
                                        ? "#fff"
                                        : undefined,
                                      "&:active": {
                                        backgroundColor: "#f8defff5",
                                      },
                                    }),
                                  }}
                                  onChange={(option) =>
                                    handleChange("designation", option)
                                  }
                                  onMenuOpen={() => {
                                    if (!formData?.department) {
                                      toast.error(
                                        "Please Select Department First"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">{phone || "N/A"}</div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">{email || "N/A"}</div>
                            </li>
                            <li>
                              <div className="title">Date of Birth:</div>
                              <div className="text">
                                {dateOfBirth ? formatDate(dateOfBirth) : "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">
                                {gender || "Not Selected"}
                              </div>
                            </li>
                            <li>
                              <div className="col-md-6">
                                <div className="input-block mb-3">
                                  <label className="col-form-label">
                                    Department{" "}
                                    <span className="text-danger">*</span>{" "}
                                    {formData?.department?.name || "N/A"}
                                  </label>
                                  <Select
                                    options={departmentData}
                                    value={departmentData.find(
                                      (option) =>
                                        option.value ===
                                        formData.department?._id
                                    )}
                                    placeholder={
                                      formData?.department?.name ||
                                      "Select Department"
                                    }
                                    styles={{
                                      ...dropdownStyle,
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#cb6ce6"
                                          : state.isFocused
                                          ? "#f8defff5"
                                          : undefined,
                                        color: state.isSelected
                                          ? "#fff"
                                          : undefined,
                                        "&:active": {
                                          backgroundColor: "#f8defff5",
                                        },
                                      }),
                                    }}
                                    onChange={(option) =>
                                      handleChange("department", option)
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="title">Address:</div>
                              <div className="text">
                                {address || "No Address"}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProfileTab userDetails={formData} />
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile;
