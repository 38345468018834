import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/api/fetchHook";
import { Avatar_09 } from "../../../Routes/ImagePath";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import { Table, Pagination } from "antd";
import Select from "react-select";
import { fetchDepartments } from "../../../store/slices/EmployeeSlice";
import { toast } from "react-toastify";

const TableAvatar = () => {
  const [attendance, setAttendance] = useState({});
  const [searchName, setSearchName] = useState("");
  const [searchMonth, setSearchMonth] = useState(new Date().getMonth() + 1);
  const [searchYear, setSearchYear] = useState(new Date().getFullYear());
  const [focused, setFocused] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalDays = new Date(searchYear, searchMonth, 0).getDate();
  const dispatch = useDispatch();
  const departments = useSelector((state) => state?.employee?.departments);

  useEffect(() => {
    dispatch(fetchDepartments());
    setSelectedDepartment({ value: null, label: "All" });
  }, [dispatch]);

  const today = new Date();
  const todayDay = today.getDate();

  const userToken = useSelector((state) => state?.auth?.userToken);
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const startDate = new Date(searchYear, searchMonth - 1, 1).toISOString();
  const endDate = new Date(
    searchYear,
    searchMonth,
    0,
    23,
    59,
    59,
    999
  ).toISOString();

  const { apiData, isLoading, isError } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/?startDate=${startDate}&endDate=${endDate}`,
    options
  );

  const monthOptions = [
    ...Array.from({ length: 12 }, (_, i) => ({
      value: i + 1,
      label: new Date(0, i).toLocaleString("default", { month: "long" }),
    })),
  ];

  const yearOptions = [
    ...Array.from({ length: 5 }, (_, i) => ({
      value: new Date().getFullYear() - i,
      label: (new Date().getFullYear() - i).toString(),
    })),
  ];

  const getFormattedDate = (day, month, year) => {
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  useEffect(() => {
    if (apiData?.data?.attendance) {
      const usersAttendance = apiData?.data?.attendance;

      const currentAttendance = {};
      usersAttendance.forEach((attendance) => {
        const userId = attendance?.user?._id;

        if (!userId) return;

        if (!currentAttendance[userId]) {
          currentAttendance[userId] = {};
        }

        const entryDate = new Date(attendance.date);
        const day = entryDate.getDate();
        const month = entryDate.getMonth() + 1; // Months are 0-based
        const year = entryDate.getFullYear();

        const dateKey = getFormattedDate(day, month, year);
        currentAttendance[userId][dateKey] = attendance;
      });

      setAttendance(currentAttendance); // Store attendance per user by date
    }
  }, [apiData]);

  const handleShowAttendance = (attendance) => {
    setSelectedAttendance(attendance);
  };

  // Filter attendance data by name
  const filteredAttendance = Object.entries(attendance)
    .filter(([key, value]) => {
      const user = value[Object.keys(value)[0]]?.user;
      const fullName = `${user?.firstName} ${user?.lastName}`.toLowerCase();
      const departmentMatch =
        !selectedDepartment ||
        selectedDepartment.value === null ||
        selectedDepartment.value === user?.department;

      return fullName.includes(searchName.toLowerCase()) && departmentMatch;
    })
    .map(([key, value]) => ({ key, value }));

  if (isError) {
    return <Error404 />;
  }

  if (isError) {
    toast.error("Error:", isError);
  }

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      fixed: "left",
      width: 200,
      render: (_, record) => {
        const user = record.value[Object.keys(record.value)[0]]?.user;

        return (
          <h2
            className="table-avatar"
            style={{
              position: "sticky",
              left: 0,
              zIndex: 2,
              width: "100%",
              height: "100%",
            }}
          >
            <Link className="avatar avatar-xs" to="/profile">
              <img
                src={user?.image ? user?.image : Avatar_09}
                alt=""
                style={{
                  borderRadius: "50%",
                }}
              />
            </Link>
            <Link to="/profile" style={{ marginLeft: "8px", color: "#000" }}>
              {user?.firstName} {user?.lastName}
            </Link>
          </h2>
        );
      },
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#fff",
          position: "sticky",
          left: 0,
          zIndex: 3,
        },
      }),
    },
    {
      title: "Total Attendance",
      dataIndex: "totalAttendance",
      key: "totalAttendance",
      render: (_, record) => {
        const presentDays = Object.keys(record.value).length;
        return <h2>{presentDays || 0}</h2>;
      },
      sorter: (a, b) => {
        return Object.keys(a.value).length - Object.keys(b.value).length;
      },
    },
    // Generate columns for days of the month
    ...Array.from(
      {
        length:
          searchMonth === today.getMonth() + 1 &&
          searchYear === today.getFullYear()
            ? todayDay
            : totalDays,
      },
      (_, i) => ({
        title: (
          <div
            style={{
              display: "inline-block",
              padding: "5px 8px",
              borderRadius: "6px",
              height: "32px",
              backgroundColor:
                i + 1 === today.getDate() &&
                searchMonth === today.getMonth() + 1 &&
                searchYear === today.getFullYear()
                  ? "#000"
                  : "transparent",
              color:
                i + 1 === today.getDate() &&
                searchMonth === today.getMonth() + 1 &&
                searchYear === today.getFullYear()
                  ? "#fff"
                  : "#000",
            }}
          >
            {i + 1}
          </div>
        ),
        dataIndex: `day${i + 1}`,
        key: `day${i + 1}`,
        render: (_, record) => {
          const dateKey = getFormattedDate(i + 1, searchMonth, searchYear);

          return record.value[dateKey] ? (
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#attendance_info"
              onClick={() => handleShowAttendance(record.value[dateKey])}
            >
              <i className="fa fa-check text-success" />
            </Link>
          ) : (
            <i className="fa fa-close text-danger" />
          );
        },
      })
    ),
  ];

  const handleDepartmentChange = (option) => {
    setSelectedDepartment(option || null);
  };

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cb6ce6"
        : state.isFocused
        ? "#f8defff5"
        : undefined,
      color: state.isSelected ? "#fff" : undefined,
      "&:active": {
        backgroundColor: "#f8defff5",
      },
    }),
  };
  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focused || searchName !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={searchName}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(searchName !== "")}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <label className="focus-label" onClick={() => setFocused(true)}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={[
                { value: null, label: "All" },
                ...departments.map((dept) => ({
                  value: dept._id,
                  label: dept.name,
                })),
              ]}
              styles={dropdownStyle}
              placeholder="Select Department"
              onChange={handleDepartmentChange}
              value={selectedDepartment}
            />
            <label className="focus-label">Select Department</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              styles={dropdownStyle}
              options={monthOptions}
              placeholder="Select Month"
              onChange={(selectedOption) =>
                setSearchMonth(selectedOption?.value || "")
              }
              value={monthOptions.find(
                (option) => option.value === searchMonth
              )}
            />
            <label className="focus-label">Select Month</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={yearOptions}
              styles={dropdownStyle}
              placeholder="Select Year"
              onChange={(selectedOption) =>
                setSearchYear(selectedOption?.value || "")
              }
              value={yearOptions.find((option) => option.value === searchYear)}
            />
            <label className="focus-label">Select Year</label>
          </div>
        </div>
        {/* <div className="col-sm-6 col-md-3">
          <Link
            className="btn btn-success btn-block w-100"
          >
            Search
          </Link>
        </div> */}
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive mb-3">
            <Table
              columns={columns}
              dataSource={filteredAttendance}
              rowKey="id"
              className="table table-striped custom-table mb-0"
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: filteredAttendance.length,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
              locale={{
                emptyText: isLoading ? <Loading /> : "No Data",
              }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      <AttendenceModelPopup attendanceData={selectedAttendance} />
    </>
  );
};

export default TableAvatar;
