import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useFetch from "../../../../../hooks/api/fetchHook";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import EstimatesTable from "./estimatesTable";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import { fetchAllEmployees } from "../../../../../store/slices/EmployeeSlice";
import { toast } from "react-toastify";
const Estimates = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [last4Digits, setLast4Digits] = useState("");
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [salesAssociate, setSalesAssociate] = useState("");
  const [followupAssociate, setFollowUpAssociate] = useState("");

  const [filter, setFilter] = useState({});

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleDateChangeTwo = (date) => {
    setEndDate(date);
  };

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, error } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/fields`,
    options
  );

  useEffect(() => {
    if (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [error]);

  const typeOptions = apiData?.data
    ? Array.from(new Set(apiData.data.map((item) => item.type))).map(
      (type) => ({
        value: type,
        label: type,
      })
    )
    : [];

  const usersData = useSelector((state) => state?.employee?.allEmployees || []);

  useEffect(() => {
    dispatch(fetchAllEmployees({}));
  }, [dispatch]);

  const usersOptions = [{ value: '', label: 'All' }, ...usersData
    .filter((user) => user?.department?.type === "sales")
    .map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }))];

  const followupOptions = [
    { value: '', label: 'All' },
    { value: 'none', label: 'N/A' },
    ...usersData.filter((user) => user?.department?.type === "followup")
      .map((user) => ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }))];

  const nameOptions = selectedType
    ? apiData?.data
      .filter((item) => item.type === selectedType)
      .map((item) => ({ value: item.name, label: item.name }))
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#ff9b44" : provided.borderColor,
      paddingTop: "10px",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  function toCamelCase(str) {
    if (!str) return ""; // Handle empty strings or null values
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  const handleSearch = () => {
    let createdAt = {};
    if (startDate) createdAt.$gte = startDate;
    if (endDate) createdAt.$lte = endDate;

    try {
      const filter = {
        ...(createdAt ? { createdAt } : {}),
        ...(selectedType
          ? { [toCamelCase(selectedType)]: selectedOption ?? "" }
          : {}),
        ...(salesAssociate ? { employeeId: salesAssociate } : {}),
        ...(followupAssociate ? { assignedTo: followupAssociate === 'none' ? { $exists: false } : followupAssociate } : {}),
        ...(last4Digits ? { last4Digits: last4Digits } : {}),
      };

      setFilter(filter);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedType(null);
    setSelectedOption(null);
    setLast4Digits("");
    setFocusedTwo(false);
    setSalesAssociate("");
    setFollowUpAssociate("");
    setFilter({});
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Sales"
            title="Main"
            subtitle="Sales"
            modal="#"
            name="Create Sales"
            link="/create-sale"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${startDate ? "focused" : ""
                  }`}
                style={{ zIndex: "6" }}
              >
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={startDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${endDate ? "focused" : ""
                  }`}
                style={{ zIndex: "6" }}
              >
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={endDate}
                    onChange={handleDateChangeTwo}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className="input-block mb-3 form-focus select-focus"
                style={{ zIndex: "5" }}
              >
                <Select
                  placeholder="Select Follow Up Associates"
                  onChange={(user) => setFollowUpAssociate(user.value)}
                  options={followupOptions}
                  value={
                    followupOptions.find((option) => option.value === followupAssociate) || null
                  }
                  styles={customStyles}
                />
                <label className="focus-label">Follow Up Associate</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className="input-block mb-3 form-focus select-focus"
                style={{ zIndex: "5" }}
              >
                <Select
                  placeholder="Select Sales Associates"
                  onChange={(user) => setSalesAssociate(user.value)}
                  options={usersOptions}
                  value={
                    usersOptions.find((option) => option.value === salesAssociate) || null
                  }
                  styles={customStyles}
                />
                <label className="focus-label">Sales Associate</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className="input-block mb-3 form-focus select-focus"
                style={{ zIndex: "5" }}
              >
                <Select
                  placeholder="Select Type"
                  onChange={type => setSelectedType(type.value)}
                  options={typeOptions}
                  value={
                    typeOptions.find((option) => option.value === selectedType) || null
                  }
                  styles={customStyles}
                />
                <label className="focus-label">Type</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Option"
                  onChange={option => setSelectedOption(option.value)}
                  options={nameOptions}
                  styles={customStyles}
                  value={
                    nameOptions.find((option) => option.value === selectedOption) || null
                  }
                  isDisabled={!selectedType}
                />
                <label className="focus-label">Options</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={
                  focusedTwo || last4Digits !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={last4Digits}
                  onFocus={() => setFocusedTwo(true)}
                  onBlur={() => setFocusedTwo(last4Digits !== "")}
                  onChange={(e) => setLast4Digits(e.target.value)}
                />
                <label
                  className="focus-label"
                  onClick={() => setFocusedTwo(true)}
                >
                  last 4 Digits
                </label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <Link
                className="btn btn-success btn-block w-100"
                onClick={handleSearch}
              >
                Search
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <Link
                className="btn btn-info btn-block w-100"
                onClick={handleReset}
              >
                Reset Filters
              </Link>
            </div>
          </div>
          <div>
            <EstimatesTable filter={filter} />
          </div>
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default Estimates;
